import Nav from './components/Nav/Nav'
import { AnimatedText } from './hooks/AnimatedText'
// USE NPM NOT YARN

function App() {
  return (
    <div className='App'>
      <Nav />
      <header>
        <AnimatedText textColor='#666666' overlayColor='#3254ff'>
          Hi, I'm Elijah
        </AnimatedText>
        <p>Frontend Developer</p>
      </header>
    </div>
  )
}

export default App
